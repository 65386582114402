<section class="section bg-light feather-bg-img" style="background-image: url(assets/images/features-bg-img.png);"
  id="services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Technical Services</h3>
          <p class="text-muted font-size-15">VIRA Solutions Inc was created to empower businesses with technical services
            and provide automation to improve their productivity and efficiency.</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/features-img.png" alt="" class="img-fluid d-block mx-auto">
        </div>
      </div>

      <div class="col-lg-5 offset-lg-1">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> Valuable Services</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Build <b>technology</b> & <b>automation</b> with our
          suite of <b>valuable services</b></h3>
        <p class="text-muted font-size-15 mb-4">With our experience in multiple industries
          we provide a wide range of technical services by following industry best practices.</p>
        <p class="text-muted mb-2">
          <i-feather class="icon-xs mr-1" name="layout"></i-feather> Small or big requirements? We provide the
          service.
        </p>
        <p class="text-muted">
          <i-feather class="icon-xs mr-1" name="life-buoy"></i-feather> Below is a brief overview of our suite of services.
        </p>
        <div class="mt-5">
          <a href="javascript: void(0);" class="btn btn-primary mr-2"><span>Read More</span></a>
          <a href="#contact" class="btn btn-soft-primary">Contact Us</a>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-4">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-database"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Database Solutions</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-apps"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Web Development</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-window-restore"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Application Development</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-dip-switch"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Enterprise Solutions</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
          </div>
          <div class="col-md-4">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-dns"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Infrastructure Solutions</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-chart-areaspline"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Business Intelligence</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-lumx"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Legacy Migration</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-security"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Cybersecurity</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
          </div>
          <div class="col-md-4">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-server-security"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Software Auditing</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-hexagon-multiple"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Project Management</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-wrench"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Software Maintenance</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-cloud"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Cloud Solutions & Migration</h5>
              <!-- <p class="text-muted mb-0 font-size-15 wc-subtitle">Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem.</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
