<!-- Contact Us Start -->
<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Contact Us</h3>
          <p class="text-muted font-size-15"> Ask VIRA to help you reach your potential.</p>
        </div>
      </div>
    </div>
    <!--
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="custom-form mb-5 mb-lg-0">
          <div id="message"></div>
          <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Name*</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="Your name..." formControlName="Name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email Address*</label>
                  <input id="email" type="email" class="form-control" autocomplete="off" placeholder="Your email..." formControlName="Email">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="comments">Message*</label>
                  <textarea id="comments" rows="4" class="form-control" placeholder="Your message..." formControlName="Comment"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary" [disabled]="!FormData.valid">Send Message <i-feather class="icon-size-15 ml-2 icon"
                    name="send"></i-feather></button>
              </div>
            </div>
          </form>
        </div>
      </div> -->
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="mx-auto" style="width: 220px">
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="mail"></i-feather> : <span>askVIRA@viraslns.com</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="link"></i-feather> : <span>www.viraslns.com</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="phone-call"></i-feather> : <span>(949) 424 7453</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="map-pin"></i-feather> : <span>Tustin, CA. USA</span>
          </p>
        </div>
      </div>
    </div> 
  </div>
</section>
<!-- Contact Us End -->
