<!-- Why Choose Us Start -->
<section class="section" id="choose">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="mb-4 mb-lg-0">
            <div class="p-2 bg-soft-primary d-inline-block rounded mb-4">
              <div class="icon-xxl uim-icon-primary"><span class="uim-svg"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24" width="1em">
                    <polygon class="uim-primary" points="12 12.3 3.5 7.05 12 1.8 20.5 7.05 12 12.3"></polygon>
                    <polygon class="uim-quaternary" points="12 22.2 12 12.3 20.5 7.05 20.5 16.95 12 22.2"></polygon>
                    <polygon class="uim-tertiary" points="12 22.2 3.5 16.95 3.5 7.05 12 12.3 12 22.2"></polygon>
                  </svg></span>
              </div>
            </div>
            <h3 class="">Why Choose Us ?</h3>
            <p class="text-muted mb-4">We provide custom solutions to meet your needs, be it a small or big project. Our team
              offers 30 years of experience.</p>
            <a href="javascript: void(0);" class="btn btn-outline-primary"><span>Learn More</span></a>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-md-6">
              <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
                <div class="wc-box-icon">
                  <i class="mdi mdi-collage"></i>
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">Versatile</h5>
                <p class="text-muted mb-0 font-size-15 wc-subtitle">Multiple industry experience with custom
                  IT services.</p>
              </div>
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <i class="mdi mdi-altimeter"></i>
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">Innovative</h5>
                <p class="text-muted mb-0 font-size-15 wc-subtitle">Out of the box solutions to cater client's unique
                  problems.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <i class="mdi mdi-shield-half-full"></i>
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">Reliable</h5>
                <p class="text-muted mb-0 font-size-15 wc-subtitle">We conduct our business with honesty, commitment, quality 
                  and respect.</p>
              </div>
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <i class="mdi mdi-vector-circle"></i>
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">Adept</h5>
                <p class="text-muted mb-0 font-size-15 wc-subtitle">Experienced engineers will help you stay focused on 
                  your goals.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Why Choose Us End -->
  