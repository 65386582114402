<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)" id="about">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <p class="font-weight-medium text-uppercase mb-2"><i
              class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> <b>About VIRA Solutions Inc.</b> </p>
          <h3 class="font-weight-semibold line-height-1_4 mb-4">We do the geeky stuff so you can <b>stay focused</b> on your<b> passion</b>.</h3>
          <!-- <h3 class="font-weight-semibold line-height-1_4 mb-4">Build <b>community</b> & <b>conversion</b> with our suite of <b>social tool</b></h3> -->
          <p class="text-muted font-size-15 mb-4"> VIRA Solutions Inc. was created because we saw the need for improvements that many businesses could make to gain competitive advantage, increase productivity and security .  Contact us to schedule a free consultation with our well experienced software architect with a deep understanding of infrastructure design.</p>
          <p class="text-muted mb-2">
            <i class="mdi mdi-account-star mr-2"></i> 
            Raspinder Seewat :
            Co-founder, Client Relations, Explorer. 
          </p>
          <p class="text-muted">
            <i class="mdi mdi-account-star mr-2"></i> Vijay Rajaram : 
            Co-founder, Chief technologist, Food connoisseur.
          </p> 
          <div class="mt-5">
            <a href="javascript: void(0);" class="btn btn-primary mr-2"><span>Read More</span></a>
            <a href="#contact" class="btn btn-soft-primary">Contact Us</a>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="mt-4 mt-lg-0">
            <img src="assets/images/features-img-1.png" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
    </div>
  </section>